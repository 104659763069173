import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "productWrapper", "category" ]

  connect() {
    setTimeout(() => {
      this.checkValidity()
    }, 500)
  }

  delayedCheckValidity() {
    setTimeout(() => {
      this.checkValidity()
    }, 500)
  }

  checkValidity() {
    var divs = this.categoryTargets
    var valid = false

    // per category
      // at least one service
      // at least product (if products exist)
      // at least service (if products exist)
    for (var i = 0; i < divs.length; i++) {
      var div = $(divs[i]);
      if (!div.is(':hidden') && div.find("[data-target='categories.category']").val() != "") {
        valid = true
        valid = valid && (!!div.find('.min-average-input').val())
        valid = valid && (!!div.find('.max-average-input').val())

        var selectedServices = div.find('*[data-target="categories.serviceButton"].checked')
        valid = valid && (selectedServices.length > 0)

        var products = div.find('*[data-target="categories.productButton"]:visible')
        var selectedProducts = div.find('*[data-target="categories.productButton"]:visible.checked')
        if (products.length) {
          valid = valid && (selectedProducts.length > 0)
        }

        var styles = div.find('*[data-target="categories.styleButton"]:visible')
        var selectedStyles = div.find('*[data-target="categories.styleButton"]:visible.checked')
        if (styles.length) {
          valid = valid && (selectedStyles.length > 0)
        }

        if (!valid) {
          break;
        }
      }
    }

    SidebarValidation(".product-services", valid)
  }
}
