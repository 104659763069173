/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// console.log('Hello World from Webpacker')

import "@stimulus/polyfills";
import Stickyfill from "stickyfilljs";

require("chartkick");
require("chart.js");

//
import tippy, { delegate } from "tippy.js";
import "tippy.js/dist/tippy.css";
window.tippy = tippy;
window.delegate = delegate;

//
import "alpinejs";

import "./stylesheet";

var elements = document.querySelectorAll(".sticky");
const objectFitImages = require("object-fit-images");

$(document).ready(function () {
  Stickyfill.add(elements);
  objectFitImages("img");

  tippy("[data-tippy-content]", { animation: "fade", arrow: true });
});

document.addEventListener("turbolinks:load", function () {
  tippy("[data-tippy-content]", { animation: "fade", arrow: true });
});

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();
const context = require.context("controllers", true, /.js$/);
application.load(definitionsFromContext(context));
