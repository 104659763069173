import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["open", "close", "checked", "day", "custom", "customopen", "customclose", "formCheckbox"]

  initialize() {
    var isChecked = $(this.formCheckboxTarget).is(':checked');

    // open
    var str = '09:00'
    var hours = $(this.openTargets[0]).find(":selected").text();
    var mins = $(this.openTargets[1]).find(":selected").text();
    str = str.substr(0,0)+hours+str.substr(0+2);
    str = str.substr(0,3)+mins+str.substr(3+2);
    $(this.customopenTarget).inputmask({regex: "([01]\\d|2[0-3])(:)\:?([0-5]\\d", placeholder: str, clearMaskOnLostFocus: false});

    // close
    var str = '17:00'
    var hours = $(this.closeTargets[0]).find(":selected").text();
    var mins = $(this.closeTargets[1]).find(":selected").text();
    str = str.substr(0,0)+hours+str.substr(0+2);
    str = str.substr(0,3)+mins+str.substr(3+2);
    $(this.customcloseTarget).inputmask({regex: "([01]\\d|2[0-3])(:)\:?([0-5]\\d", placeholder: str, clearMaskOnLostFocus: false});

    if (!isChecked) {
      this.customopenTarget.setAttribute("disabled", true)
      $(this.customopenTarget).inputmask({clearMaskOnLostFocus: true});
      this.customcloseTarget.setAttribute("disabled", true)
      $(this.customcloseTarget).inputmask({clearMaskOnLostFocus: true});
    }
  }

  open() {
    if (!this.checkedTarget.classList.contains('checked')) {
      $(this.formCheckboxTarget).attr("checked", true)

      this.customTargets.forEach(function (element) {
        element.removeAttribute("disabled")
        element.selectedIndex = 0;
        $(element).inputmask({clearMaskOnLostFocus: false});
      })

      this.checkedTarget.classList.add("checked")
    } else {
      $(this.formCheckboxTarget).attr("checked", false)
      this.checkedTarget.classList.remove("checked")

      this.customTargets.forEach(function (element) {
        element.setAttribute("disabled", true)
        $(element).inputmask({clearMaskOnLostFocus: true});
        $(element).val("");
        $(element).removeAttr('placeholder');
      })
    }

    incrementChangeCount()
  }

  getOpenTime(event){
    var value = event.currentTarget.inputmask._valueGet()
    var hours = value.substring(0,2)
    var minutes = value.substring(3,5)
    $(this.openTargets[0]).val(hours)
    $(this.openTargets[1]).val(minutes)
  }

  getCloseTime(event){
    var value = event.currentTarget.inputmask._valueGet()
    var hours = value.substring(0,2)
    var minutes = value.substring(3,5)
    $(this.closeTargets[0]).val(hours)
    $(this.closeTargets[1]).val(minutes)
  }
}
