import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [""];

  initialize() {
    $(".js-plan").each(function (idx, item) {
      if ($(item).data("interval") == "monthly") {
        $(item).addClass("tw-hidden");
      }
    });

    this.interval = "yearly";
  }

  selectInterval(event) {
    const interval = $(event.currentTarget).data("interval");
    if (this.interval !== interval) {
      this.interval = interval;
      $(event.currentTarget).toggleClass(
        "tw-text-white tw-border-primary tw-bg-primary tw-border-gray-300 tw-text-gray-700 tw-bg-white"
      );

      $(event.currentTarget)
        .siblings()
        .toggleClass(
          "tw-text-white tw-border-primary tw-bg-primary tw-border-gray-300 tw-text-gray-700 tw-bg-white"
        );

      $(".js-plan").each(function (idx, item) {
        if ($(item).data("interval") == interval) {
          $(item).removeClass("tw-hidden");
        } else {
          if ($(item).data("interval") != "free") {
            $(item).addClass("tw-hidden");
          }
        }
      });
    }
  }
}
