import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "charsremaining", "text" ]

  initialize() {
    var length = this.element.getAttribute("data-length")
    var number = length - this.textTarget.value.length
    this.charsremainingTarget.innerHTML = number + " characters remaining"

    if(length == 2000){
      SidebarValidation(".description", this.textTarget.value.length)
    } else if (length == 100){
      SidebarValidation(".bio-intro", this.textTarget.value.length)
    }
  }

  typing(){
    var length = this.element.getAttribute("data-length")
    var number = length - this.textTarget.value.length
    var string = number + " characters remaining"
    this.charsremainingTarget.innerHTML = string
    
    if(length == 2000){
      SidebarValidation(".description", this.textTarget.value.length)
    } else if (length == 100){
      SidebarValidation(".bio-intro", this.textTarget.value.length)
    }
  }

  incrementChangeCount(){
    incrementChangeCount()
  }
}
