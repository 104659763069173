import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "annualBox", "annualButton", "monthlyBox", "monthlyButton", "monthlySummary", "annualSummary" ]

  connect() {
    this.configureButton();
    
    var typeParam = window.location.search.substr(1).split("=")[1]
    if(typeParam !== undefined){
      this.selectPlan(typeParam)
    }
  }

  selectPlan(param){
    // calculations
    var type = param.length ? param : $(event.target).parents("[data-type]").attr("data-type")

    if (type === "annual"){
      $(this.monthlySummaryTarget).addClass('d-none')
      $(this.annualSummaryTarget).removeClass('d-none')
    } else{
      $(this.annualSummaryTarget).addClass('d-none')
      $(this.monthlySummaryTarget).removeClass('d-none')
    }

    // hidden input with plan type
    $("#plan_type").val(type)

    //
    $(".coupon-input").attr("data-plan-type", type)

    this.configureButton()
  }

  configureButton() {
    var type = $("#plan_type").val();

    var selected;
    var unselected;
    if (type == "annual") {
      selected = $(this.annualBoxTarget);
      unselected = $(this.monthlyBoxTarget);
    } else {
      selected = $(this.monthlyBoxTarget);
      unselected = $(this.annualBoxTarget);
    }

    selected.removeClass("border").addClass("selected-box")
    var selected_btn = selected.find(".btn")
    selected_btn.html("Selected").removeClass("btn-plain").addClass("btn-primary")

    unselected.removeClass("selected-box").addClass("border")
    var unselected_btn = unselected.find(".btn")
    unselected_btn.html("Select plan").removeClass("btn-primary").addClass("btn-plain")
  }

}
