import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [
    "category",
    "services",
    "serviceIds",
    "serviceButton",
    "productIds",
    "productButton",
    "styleIds",
    "styleButton"
  ]

  connect() {
    this.showCorrectElement()
  }

  toggleService(event){
    var self = this;

    this.serviceButtonTargets.forEach((element, idx) => {
      if (element == event.currentTarget){
        if ($(event.currentTarget).hasClass("checked")){
          $(event.currentTarget).removeClass("checked")
          self.serviceIdsTarget.options[idx].selected = false
        } else{
          $(event.currentTarget).addClass("checked")
          self.serviceIdsTarget.options[idx].selected = true
        }
      }
    })

    incrementChangeCount()
  }

  toggleProduct(event) {
    var self = this;

    this.productButtonTargets.forEach((element, idx) => {
      if (element == event.currentTarget){
        if ($(event.currentTarget).hasClass("checked")){
          $(event.currentTarget).removeClass("checked")
          self.productIdsTarget.options[idx].selected = false
        } else{
          $(event.currentTarget).addClass("checked")
          self.productIdsTarget.options[idx].selected = true
        }
      }
    })

    incrementChangeCount()
  }

  toggleStyle(event) {
    var self = this;

    this.styleButtonTargets.forEach((element, idx) => {
      if (element == event.currentTarget){
        if ($(event.currentTarget).hasClass("checked")){
          $(event.currentTarget).removeClass("checked")
          self.styleIdsTarget.options[idx].selected = false
        } else{
          $(event.currentTarget).addClass("checked")
          self.styleIdsTarget.options[idx].selected = true
        }
      }
    })

    incrementChangeCount()
  }

  showCorrectElement() {
    var value = this.categoryTarget.value;
    var divs = this.servicesTargets;

    for (var i = 0; i < divs.length; i++) {
      var div = divs[i];
      var id = div.getAttribute('data-product-id');
      if (value === id) {
        div.style.display = 'block';
      } else {
        div.style.display = 'none';
      }
    }
  }

}
