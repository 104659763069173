import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "button" ]

  connect() {
    this.checkValidity()
  }

  checkValidity(){
    let content = this.inputTarget.value
    if (content && content.length) {
      this.inputTarget.value = this.inputTarget.value.toUpperCase()

      this.buttonTarget.href = this.data.get("base-link") + "?coupon=" + this.inputTarget.value + "&type=" + $(this.inputTarget).data("plan-type")
      this.buttonTarget.classList.remove('disabled')
    } else {
      this.buttonTarget.href = "#"
      this.buttonTarget.classList.add('disabled')
    }
  }

  submit() {
    this.checkValidity()
    if (!$(this.buttonTarget).hasClass("disabled")) {
      $(".btn-submit").addClass("disabled")
      this.buttonTarget.click()
    }
  }
}
