import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["textarea", "submit", "promptInput", "prompt", "promptButton", "cancelPrompt"]

  connect() {
    this.replyDidChange()
  }

  replyDidChange() {
    var valid = $(this.textareaTarget).val().length > 0
    $(this.submitTarget).attr('disabled', !valid)
  }

  prompt(e) {
    e.preventDefault()

    $(this.promptTargets).removeClass('d-none')
    $(this.cancelPromptTarget).removeClass('d-none')
    $(this.promptButtonTarget).addClass('d-none')

    $(this.textareaTarget).attr('placeholder', 'Type your message here...')
    $(this.submitTarget).attr('value', 'Prompt For Review')

    $(this.promptInputTarget).attr('checked', true)

    return false
  }

  cancelPrompt(e) {
    e.preventDefault()

    $(this.promptTargets).addClass('d-none')
    $(this.cancelPromptTarget).addClass('d-none')
    $(this.promptButtonTarget).removeClass('d-none')

    $(this.textareaTarget).attr('placeholder', 'Type your reply here...')
    $(this.submitTarget).attr('value', 'Send')

    $(this.promptInputTarget).attr('checked', false)

    return false
  }
}
