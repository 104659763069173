import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  initialize(){
  }

  selectChart(event){
    const wrapper_class = "." + $(event.currentTarget).data("class")

    // 
    $(event.currentTarget).addClass("btn-primary").removeClass("btn-secondary")
    $(event.currentTarget).siblings().addClass("btn-secondary").removeClass("btn-primary")

    // 
    $(".charts").addClass("d-none")
    $(wrapper_class).removeClass("d-none")
  }
}
