import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content", "preview", "heightInput", "urlSelect"];

  initialize() {
    var default_logo = $(".logo-image")[0];

    this.image_url = $(default_logo).data("absolute");
    this.url = $(this.urlSelectTarget).find(":selected").data("url");
    this.altText = $(this.urlSelectTarget).find(":selected").data("alt-text");
    this.image_size = $(this.heightInputTarget).val();

    this.changeCode();
  }

  selectImage(event) {
    this.image_url = $(event.currentTarget).data("absolute");

    this.changeCode();
  }

  selectUrl(event) {
    this.url = $(event.target).find(":selected").data("url");
    this.altText = $(event.target).find(":selected").data("alt-text");

    this.changeCode();
  }

  selectSize(event) {
    this.image_size = $(event.currentTarget).val();

    // enforce max height
    if (this.image_size > 100) {
      $(event.currentTarget).val(100);
      this.image_size = 100;
    }

    this.changeCode();
  }

  changeCode() {
    if (this.image_size == undefined || this.image_size == 0) {
      this.image_size = 40;
    }

    var code = `<a href="${this.url}" target="_blank" rel="noopener noreferrer"><img src="${this.image_url}" alt="${this.altText}" height="${this.image_size}" /></a>`;
    $(this.contentTarget).val(code);

    $(this.previewTarget).html(code);
  }

  copy() {
    this.contentTarget.select();
    document.execCommand("copy");

    alert("Copied to your clipboard.");
  }
}
