import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  limitInputChars(e) {
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }

    e.preventDefault();
    return false;
  }

  uppercaseInput(e) {
    e.target.value = e.target.value.toUpperCase();
  }
}
