import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "checkbox", "button", "checkboxInput" ]

  connect() {
    this.buttonTarget.disabled = true
    this.buttonTarget.classList.add("disabled");
  }

  accept(){
    if($(this.checkboxTarget).hasClass("checked")){
      $(this.checkboxTarget).removeClass("checked");
      this.buttonTarget.classList.add("disabled");
      this.buttonTarget.disabled = true
      this.checkboxInputTarget.checked = false
    }else{
      $(this.checkboxTarget).addClass("checked");
      this.buttonTarget.classList.remove("disabled");
      this.buttonTarget.disabled = false
      this.checkboxInputTarget.checked = true
    }
  }
}
