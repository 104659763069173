import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "modal",
    "planInput",
    "planName",
    "planCost",
    "yearlyMode",
    "monthlyMode",
    "planMode",
    "planModeAdverb",
    "vatCost",
    "totalCost",
    "selectButton",
  ];

  initialize() {
    this.mode = $(".js-current-interval").text();

    const currentPlan = $(".js-current-plan")[0];
    if (currentPlan) {
      this.planName = currentPlan.getAttribute("data-plan-name");

      this.planIdMonthly = currentPlan.getAttribute("data-plan-id-monthly");
      this.planCostMonthly = currentPlan.getAttribute("data-plan-cost-monthly");
      this.planVatCostMonthly = currentPlan.getAttribute(
        "data-plan-vat-cost-monthly"
      );
      this.planTotalCostMonthly = currentPlan.getAttribute(
        "data-plan-total-cost-monthly"
      );

      this.planIdYearly = currentPlan.getAttribute("data-plan-id-annually");
      this.planCostYearly = currentPlan.getAttribute("data-plan-cost-annually");
      this.planVatCostYearly = currentPlan.getAttribute(
        "data-plan-vat-cost-annually"
      );
      this.planTotalCostYearly = currentPlan.getAttribute(
        "data-plan-total-cost-annually"
      );
    }
  }

  selectPlan(e) {
    this.planName = e.target.getAttribute("data-plan-name");

    this.planIdMonthly = e.target.getAttribute("data-plan-id-monthly");
    this.planCostMonthly = e.target.getAttribute("data-plan-cost-monthly");
    this.planVatCostMonthly = e.target.getAttribute(
      "data-plan-vat-cost-monthly"
    );
    this.planTotalCostMonthly = e.target.getAttribute(
      "data-plan-total-cost-monthly"
    );

    this.planIdYearly = e.target.getAttribute("data-plan-id-annually");
    this.planCostYearly = e.target.getAttribute("data-plan-cost-annually");
    this.planVatCostYearly = e.target.getAttribute(
      "data-plan-vat-cost-annually"
    );
    this.planTotalCostYearly = e.target.getAttribute(
      "data-plan-total-cost-annually"
    );

    // change button copy
    $(this.selectButtonTargets).each(function (idx, item) {
      $(item).text("Select plan");
      $(item)
        .addClass("tw-bg-gray-900 hover:tw-bg-gray-800")
        .removeClass("tw-bg-primary hover:tw-bg-primary-500");
    });
    $(e.target).text("Selected plan");
    $(e.target)
      .removeClass("tw-bg-gray-900 hover:tw-bg-gray-800")
      .addClass("tw-bg-primary hover:tw-bg-primary-500");

    $(".js-pro-intro-offer-str").addClass("tw-hidden");
    if (!this.planIdMonthly) {
      // free plan
      $(".stripe-wrapper").addClass("stripe-wrapper--hidden");
      $(".stripe-wrapper").find("#card_name").removeAttr("required");
      $(".stripe-wrapper").find("#card_postcode").removeAttr("required");
      $(".js-payment-protection-str").addClass("tw-hidden");
      $(".js-free-no-card-required").removeClass("tw-hidden");
    } else {
      $(".stripe-wrapper").removeClass("stripe-wrapper--hidden");
      $(".stripe-wrapper").find("#card_name").attr("required", "required");
      $(".stripe-wrapper").find("#card_postcode").attr("required", "required");
      $(".js-payment-protection-str").removeClass("tw-hidden");
      $(".js-free-no-card-required").addClass("tw-hidden");
    }

    if (this.planName == "Pro") {
      $(".js-pro-intro-offer-str").removeClass("tw-hidden");
    }

    this.setData();
    this.closeModal();
  }

  setData() {
    $(this.planNameTarget).html(this.planName);
    $(this.planModeTarget).parent().removeClass("tw-hidden");
    $(this.planModeTarget).html(this.adjectiveMode());
    $(this.planModeAdverbTarget).html(this.mode);

    if (this.mode == "monthly") {
      $(this.planInputTarget).val(this.planIdMonthly);
      $(this.planCostTarget).html(this.planCostMonthly);
      $(this.vatCostTarget).html(this.planVatCostMonthly);
      $(this.totalCostTarget).html(this.planTotalCostMonthly);
    } else if (this.mode == "annually") {
      $(this.planInputTarget).val(this.planIdYearly);
      $(this.planCostTarget).html(this.planCostYearly);
      $(this.vatCostTarget).html(this.planVatCostYearly);
      $(this.totalCostTarget).html(this.planTotalCostYearly);
    }

    // free plan
    if (!this.planIdMonthly) {
      $(this.planModeTarget).parent().addClass("tw-hidden");
      $(this.planCostTarget).html("£0");
      $(this.vatCostTarget).html("£0");
      $(this.totalCostTarget).html("£0");
    }
  }

  setMode(e) {
    this.mode = e.target.getAttribute("data-mode");

    if (this.mode == "monthly") {
      $(this.monthlyModeTarget).toggleClass(
        "tw-text-white tw-border-primary tw-bg-primary tw-border-gray-300 tw-text-gray-700 tw-bg-white"
      );
      $(this.monthlyModeTarget)
        .siblings()
        .toggleClass(
          "tw-text-white tw-border-primary tw-bg-primary tw-border-gray-300 tw-text-gray-700 tw-bg-white"
        );
      $(".js-crossed-price").text(
        `${$(".js-crossed-price").data(
          "plan-monthly-discounted-cost-per-month"
        )}/mo`
      );
      $(".js-discounted-price").text(
        $(".js-discounted-price").data("plan-monthly-discounted-cost-per-month")
      );
      $($(".js-normal-price")[1]).text(
        $($(".js-normal-price")[1]).data("plan-monthly-cost-per-month")
      );
    } else if (this.mode == "annually") {
      $(this.yearlyModeTarget).toggleClass(
        "tw-text-white tw-border-primary tw-bg-primary tw-border-gray-300 tw-text-gray-700 tw-bg-white"
      );
      $(this.yearlyModeTarget)
        .siblings()
        .toggleClass(
          "tw-text-white tw-border-primary tw-bg-primary tw-border-gray-300 tw-text-gray-700 tw-bg-white"
        );
      $(".js-crossed-price").text(
        `${$(".js-crossed-price").data(
          "plan-annually-discounted-cost-per-month"
        )}/mo`
      );
      $(".js-discounted-price").text(
        $(".js-discounted-price").data(
          "plan-annually-discounted-cost-per-month"
        )
      );
      $($(".js-normal-price")[1]).text(
        $($(".js-normal-price")[1]).data("plan-annually-cost-per-month")
      );
    }
    this.setData();
  }

  closeModal() {
    $(".js-modal-overlay").click();
  }

  adjectiveMode() {
    if (this.mode == "monthly") {
      return "a monthly";
    } else if (this.mode == "annually") {
      return "an annual";
    }
  }
}
