import { Controller } from "stimulus"

export default class extends Controller {
  // DEPRECATED: as professional and supplier business types have been combined
  static targets = [ "" ]

  initialize() {
    if ($('#supplier_business_type').val() == "professional"){
      $(".professional").parent().removeClass("border").addClass("selected-box")
      var selected_btn = $(".professional")
      selected_btn.html("Selected").removeClass("btn-plain").addClass("btn-primary")
    } else if($('#supplier_business_type').val() == "supplier"){
      $(".supplier").parent().removeClass("border").addClass("selected-box")
      var selected_btn = $(".supplier")
      console.log(selected_btn)
      selected_btn.html("Selected").removeClass("btn-plain").addClass("btn-primary")
    }
  }

  selectType(event){
    $('#supplier_business_type').val($(event.currentTarget).parent().find(".business-type")[0].innerHTML)
    $(event.currentTarget).parent().removeClass("border").addClass("selected-box")
    var selected_btn = $(event.currentTarget).parent().find(".btn")
    selected_btn.html("Selected").removeClass("btn-plain").addClass("btn-primary")

    $(event.currentTarget).parent().parent(".col-lg-6").siblings().find(".business-type-item").removeClass("selected-box").addClass("border")
    var unselected_btn = $(event.currentTarget).parent().parent(".col-lg-6").siblings().find(".btn")
    unselected_btn.html("Select").removeClass("btn-primary").addClass("btn-plain")
  }
}
