import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["minRange", "maxRange", "prompt"];

  initialize() {
    this.handleState();
  }

  checkState() {
    this.handleState();
  }

  handleState() {
    let isCompleted = true;
    isCompleted = isCompleted && !!$(this.minRangeTarget).val();
    isCompleted = isCompleted && !!$(this.maxRangeTarget).val();
    $(this.promptTarget).toggleClass("checked", isCompleted);
  }
}
