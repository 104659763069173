import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["preview", "upload", "hiddenInput", "fileUploadBtn"];

  initialize() {
    // profile & cover photo validations
    if (
      $(".image-preview").first().is(":visible") &&
      $(".image-preview").eq(1).is(":visible")
    ) {
      SidebarValidation(".profile-photos", true);
    } else {
      SidebarValidation(".profile-photos", false);
    }
  }

  openFileUpload() {
    $(this.fileUploadBtnTarget).trigger("click");
  }

  delete(event) {
    $(this.previewTarget).addClass("d-none");
    $(this.uploadTarget).addClass("d-flex");
    $(this.uploadTarget).removeClass("d-none");
    $(this.hiddenInputTarget).children().val("");
    $(event.currentTarget).addClass("d-none");

    incrementChangeCount();
  }

  deletePortfolio() {
    $(this.previewTarget).addClass("d-none");

    const activeImages = $(this.previewTarget)
      .closest(".sortable-images")
      .find(".image.nested-fields .image-preview:visible").length;
    console.log(activeImages);
    $(this.previewTarget)
      .closest(".js-project-images-wrapper")
      .find(".js-remaining-images")
      .text(10 - Math.min(activeImages, 10));

    if (activeImages > 0 && activeImages < 10) {
      $(this.previewTarget)
        .closest(".portfolio-grid")
        .find(".upload-btn-wrapper")
        .last()
        .removeClass("tw-hidden");
    }

    // plural check
    if (activeImages === 9) {
      $(this.previewTarget)
        .closest(".js-project-images-wrapper")
        .find(".js-remaining-projects-plural")
        .addClass("tw-hidden");
    } else {
      $(this.previewTarget)
        .closest(".js-project-images-wrapper")
        .find(".js-remaining-projects-plural")
        .removeClass("tw-hidden");
    }

    incrementChangeCount();
  }
}
