import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "checkbox", "element" ]

  initialize() {
    if(this.hasCheckboxTarget && $(this.checkboxTarget).hasClass('checked')){
      $('.selectize-input').addClass("disabled-input");
      $('#supplier_place_ids-selectized').prop("disabled", true);
      $('#supplier_place_ids-selectized').removeAttr('placeholder');
      $('.selected-locations').empty()
      $('.location-selector').hide();
      SidebarValidation(".locations", true)
    } else {
      SidebarValidation(".locations", $('.form-control.select.selectize')[0].length)
    }
  }

  entireUK(){
    if($(this.checkboxTarget).hasClass('checked')){
      $(this.checkboxTarget).removeClass('checked')
      $("#supplier_entire_UK").prop('checked', false)

      $('.selectize-input').removeClass("disabled-input");
      $('#supplier_place_ids-selectized').prop("disabled", false);
      $('#supplier_place_ids-selectized').attr("placeholder", "Start typing a location");
      $('.location-selector').show();

      SidebarValidation(".locations", false)
    }else{
      $(this.checkboxTarget).addClass('checked')
      $("#supplier_entire_UK").prop('checked', true)

      // clear selectize_array
      var selectize_array = $(".selectize")[0].selectize
      selectize_array.clear()

      $("#supplier_place_ids").find('option').remove()
      $('.selectize-input').addClass("disabled-input");
      $('#supplier_place_ids-selectized').prop("disabled", true);
      $('#supplier_place_ids-selectized').removeAttr('placeholder');
      $('.selected-locations').empty();
      $('.location-selector').hide();

      SidebarValidation(".locations", true)
    }
  }

  removeLocation(e) {
    var target = $(e.target)
    var select = $('.form-control.select.selectize')[0].selectize

    // 1. Get the value
    var selectedValue = target.attr("data-value")
    // 2. Remove the option
    select.removeItem(selectedValue)
    // 3. Refresh the select
    select.refreshItems()
    select.clearOptions()
    select.refreshOptions(false)

    // remove div
    target.closest('.selected-location').remove()

    // any remaining?
    var hasItems = ($('.selected-location').length > 0)
    SidebarValidation(".locations", hasItems)

    incrementChangeCount()
  }
}
