import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["facebook", "twitter", "instagram"]

  connect() {
    var def = {
      "*": {
          validator: "[\.\\_\\-0-9\uFF11-\uFF19A-Za-z\u0410-\u044F\u0401\u0451\u00C0-\u00FF\u00B5]"
      }
    }

    $(this.facebookTarget).inputmask("f\\acebook\.com/*{0,30}", { definitions: def, placeholder: "yourprofilelink", showMaskOnHover: false });
    $(this.twitterTarget).inputmask("twitter\.com/*{0,30}", { definitions: def, placeholder: "yourprofilelink", showMaskOnHover: false });
    $(this.instagramTarget).inputmask("inst\\agr\\am\.com/*{0,30}", { definitions: def, placeholder: "yourprofilelink", showMaskOnHover: false });
  }
}
