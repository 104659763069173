import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "wrapper", "button" ]

  validateButton(event){
    if ($(event.currentTarget).val()){
      $(this.buttonTarget).removeClass('disabled')
      $(this.buttonTarget).attr("disabled", false)
    } else {
      $(this.buttonTarget).addClass('disabled')
      $(this.buttonTarget).attr("disabled", true)
    }
  }

  incrementChangeCount(){
    incrementChangeCount()
  }

  deleteProject(event) {
    $(this.wrapperTarget).addClass('d-none')

    incrementChangeCount()
  }
}
