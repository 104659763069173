import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["toggleable", "button", "expandedAttr"];

  connect() {
    this.toggleClass = this.data.get("class") || "d-none";
  }

  toggle(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    if (event.keyCode !== undefined && event.keyCode !== 13) return;

    if (this.hasExpandedAttrTarget) {
      $(this.expandedAttrTarget).prop(
        "checked",
        !$(this.expandedAttrTarget).prop("checked")
      );
    }
    if (this.hasButtonTarget) {
      var text =
        $(this.buttonTarget).text() == "Add details"
          ? "Minimise"
          : "Add details";
      $(this.buttonTarget).text(text);
    }

    this.toggleableTargets.forEach((target) => {
      target.classList.toggle(this.toggleClass);
    });
  }
}
