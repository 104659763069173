import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["newCheckbox"]

  connect() {
    this.validate()
  }

  markAsDirty() {
    $(this.newCheckboxTargets).attr('checked', false)
    this.validate()
    incrementChangeCount()
  }

  validate() {
    var isDirty = !$(this.newCheckboxTargets).is(':checked')
    var valid = isDirty
    SidebarValidation(".opening-times", valid)
  }
}
